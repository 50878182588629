import { GalaxySize } from "../interfaces/GalaxyInterfaces";
import { SectorCoordinates, SectorSize } from "../interfaces/GalaxySectorInterfaces";
import GalaxySector from "./GalaxySector";

class Galaxy {
    constructor(
        private galaxySize: GalaxySize,
        private readonly sectorSize: SectorSize,
        private readonly totalStarsPerSector: number
    ) {
        this.sectorSize = sectorSize;
        this.galaxy = [];
    }

    public getGalaxySizeX(): number {
        return this.galaxySize.x;
    }

    public getGalaxySizeY(): number {
        return this.galaxySize.y;
    }

    public getGalaxySizeZ(): number {
        return this.galaxySize.z;
    }

    private galaxy: GalaxySector[][][];

    public getGalaxy(): GalaxySector[][][] {
        return this.galaxy;
    }

    public build(): void {
        this.galaxy = [];
        for (let x = 0; x < this.galaxySize.x; x++) {
            this.galaxy[x] = [];

            for (let y = 0; y < this.galaxySize.y; y++) {
                this.galaxy[x][y] = [];

                for (let z = 0; z < this.galaxySize.z; z++) {
                    const sectorCoordinates: SectorCoordinates = {
                        x: x,
                        y: y,
                        z: z
                    };

                    const galaxySector = new GalaxySector(
                        sectorCoordinates,
                        this.sectorSize,
                        this.totalStarsPerSector
                    );

                    galaxySector.build();
                    this.galaxy[x][y][z] = galaxySector;
                }
            }
        }
    }
}

export default Galaxy;
