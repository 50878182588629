/**
 * Type representing key press event.
 */
type KeyPressEvent = {
    KeyW: boolean;
    KeyS: boolean;
    KeyA: boolean;
    KeyD: boolean;
    KeyQ: boolean;
    KeyE: boolean;
    KeySpace: boolean;
    KeyControl: boolean;
    KeyShift: boolean;
};

/**
 * Class representing keyboard controls.
 */
class KeyboardControls {
    private keys: KeyPressEvent;

    /**
     * Constructs a new instance of KeyboardControls.
     */
    constructor() {
        this.keys = {
            KeyW: false,
            KeyS: false,
            KeyA: false,
            KeyD: false,
            KeyQ: false,
            KeyE: false,
            KeySpace: false,
            KeyControl: false,
            KeyShift: false
        };

        document.addEventListener('keydown', this.handleKeyPress.bind(this) as EventListener);
        document.addEventListener('keyup', this.handleKeyPress.bind(this) as EventListener);
        document.addEventListener('keydown', this.handleSpacebar.bind(this) as EventListener);
    }

    /**
     * Handles spacebar uniquely to avoid scrolling the page.
     * @param {KeyboardEvent} event - The keyboard event.
     */
    private handleSpacebar(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
        }
    }

    /**
     * Handles key press events.
     * @param {KeyboardEvent} event - The keyboard event.
     */
    private handleKeyPress(event: KeyboardEvent) {
        const isKeyDown = event.type === 'keydown';
        const key = event.code;

        switch (key) {
            case 'KeyW':
                this.keys.KeyW = isKeyDown;
                break;
            case 'KeyS':
                this.keys.KeyS = isKeyDown;
                break;
            case 'KeyA':
                this.keys.KeyA = isKeyDown;
                break;
            case 'KeyD':
                this.keys.KeyD = isKeyDown;
                break;
            case 'KeyQ':
                this.keys.KeyQ = isKeyDown;
                break;
            case 'KeyE':
                this.keys.KeyE = isKeyDown;
                break;
            case 'Space':
                this.keys.KeySpace = isKeyDown;
                break;
            case 'ControlLeft':
            case 'ControlRight':
                this.keys.KeyControl = isKeyDown;
                break;
            case 'ShiftLeft':
                this.keys.KeyShift = isKeyDown;
                break;
        }
    }

    /**
     * Gets the key press event.
     * @returns {KeyPressEvent} The key press event object.
     */
    public getKeyPressEvent(): KeyPressEvent {
        return this.keys;
    }
}

export { KeyboardControls };
export type { KeyPressEvent };
