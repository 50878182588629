enum GalaxyConfig {
    GalaxySizeX = 3,
    GalaxySizeY = 3,
    GalaxySizeZ = 3,
    
    // Standard Values
    // SectorSizeX = window.innerWidth,
    // SectorSizeY = window.innerHeight,
    // SectorSizeZ = 1000,
    // StarsPerSector = 5000,
    
    // Debug Values
    SectorSizeX = 500,
    SectorSizeY = 500,
    SectorSizeZ = 500,
    StarsPerSector = 100,

    CameraStartPositionX = 1000,
    CameraStartPositionY = 400,
    CameraStartPositionZ = 1000,
}

export { GalaxyConfig };
