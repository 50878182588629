import { Vector3 } from 'three';
import { CameraConfig } from '../configs/CameraConfig';
import { KeyPressEvent } from './KeyboardControls';
import Camera from './Camera';

class CameraControls {
    private camera: Camera;

    constructor(camera: Camera) {
        this.camera = camera;
    }

    public updateCameraPosition(keyPressEvent: KeyPressEvent) {
        const cameraDirection = new Vector3();
        this.camera.getWorldDirection(cameraDirection);
        cameraDirection.multiplyScalar(CameraConfig.CameraSpeed);

        // Mover the camera forward, emulating flight
        if (keyPressEvent.KeySpace) {
            this.camera.position.add(cameraDirection);
        }

        // Move the camera up
        if (keyPressEvent.KeyShift) {
            const upVector = new Vector3(0, 1, 0);
            const upOffset = upVector.clone().applyQuaternion(this.camera.quaternion).multiplyScalar(CameraConfig.CameraSpeed);
            const upPosition = this.camera.position.clone().add(upOffset);
            this.camera.position.copy(upPosition);
        }
        // Move the camera down
        if (keyPressEvent.KeyControl) {
            const downVector = new Vector3(0, -1, 0);
            const downOffset = downVector.clone().applyQuaternion(this.camera.quaternion).multiplyScalar(CameraConfig.CameraSpeed);
            const downPosition = this.camera.position.clone().add(downOffset);
            this.camera.position.copy(downPosition);
        }

        // Rotate the camera left
        if (keyPressEvent.KeyA) {
            this.camera.rotateY(CameraConfig.RotationSpeed);
        }
        // Rotate the camera right
        if (keyPressEvent.KeyD) {
            this.camera.rotateY(-CameraConfig.RotationSpeed);
        }

        // Rotate the camera up
        if (keyPressEvent.KeyS) {
            this.camera.rotateX(CameraConfig.RotationSpeed);
        }
        // Rotate the camera down
        if (keyPressEvent.KeyW) {
            this.camera.rotateX(-CameraConfig.RotationSpeed);
        }

        // Rotate the camera clockwise
        if (keyPressEvent.KeyQ) {
            this.camera.rotateZ(-CameraConfig.RotationSpeed);
        }
        // Rotate the camera counter-clockwise
        if (keyPressEvent.KeyE) {
            this.camera.rotateZ(CameraConfig.RotationSpeed);
        }
    }
}

export default CameraControls;
