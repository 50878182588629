import { StarColorConfig, StarMeshMaterialConfig, StarSizeConfig } from "../configs/StarConfig";
import { SectorBounds } from "../interfaces/GalaxySectorInterfaces";
import { GlobalPosition, StarMeshMaterial } from "../interfaces/StarInterface";
import { generateRandomInt, generateRandomFloat } from "../utils/Utils";

export class Star {
    position: GlobalPosition;
    sectorBounds!: SectorBounds;
    maxSize!: number;
    meshMaterial!: StarMeshMaterial;
    size!: number;
    color!: string;

    constructor(sectorBounds: SectorBounds) {
        this.position = this.setPosition(sectorBounds);
    }

    public build(): Star {
        this.setSize();
        if (typeof this.color === "undefined") {
            this.setColor();
        }
        this.setMeshMaterial();
        return this;
    }

    public getPosition(): GlobalPosition {
        return this.position;
    }

    private setPosition(sectorBounds: SectorBounds): GlobalPosition {
        return {
            x: generateRandomInt(sectorBounds.xMin, sectorBounds.xMax),
            y: generateRandomInt(sectorBounds.yMin, sectorBounds.yMax),
            z: generateRandomInt(sectorBounds.zMin, sectorBounds.zMax)
        }
    }

    public getSize(): number {
        return this.size;
    }

    private setSize() {
        return generateRandomFloat(0, StarSizeConfig.Default);
    }

    public getColor(): string {
        return this.color;
    }

    private setColor() {
        const colors: Array<string> = [
            StarColorConfig.White,
            StarColorConfig.Blue,
            StarColorConfig.Red
        ];
        this.color = colors[generateRandomInt(0, 2)];
    }

    private setMeshMaterial() {
        this.meshMaterial = {
            color: this.color,
            roughness: StarMeshMaterialConfig.Roughness,
            metalness: StarMeshMaterialConfig.Metalness,
            emissive: this.color,
            emissiveIntensity: StarMeshMaterialConfig.EmissiveIntensity
        }
    }
}
