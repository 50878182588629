import { Star } from "./Star";
import { GlobalPosition, SectorBounds, SectorCoordinates, SectorSize } from "../interfaces/GalaxySectorInterfaces";

export default class GalaxySector {
    readonly sectorCoordinates: SectorCoordinates;
    readonly starQuantity: number;
    readonly stars: Array<Star>;
    readonly sectorSize!: SectorSize;

    constructor(sectorCoordinates: SectorCoordinates, sectorSize: SectorSize, starQuantity: number) {
        this.sectorCoordinates = sectorCoordinates;
        this.sectorSize = sectorSize;
        this.starQuantity = starQuantity;

        this.stars = [];
    }

    public build() {
        const sectorBounds: SectorBounds = this.getBounds();
        for (let i: number = 0; i < this.starQuantity; i++) {
            this.stars.push(new Star(sectorBounds)
                .build())
        }
    }

    public getBounds(): SectorBounds {
        return {
            xMin: this.sectorCoordinates.x * this.sectorSize.x,
            xMax: this.sectorCoordinates.x * this.sectorSize.x + this.sectorSize.x,
            yMin: this.sectorCoordinates.y * this.sectorSize.y,
            yMax: this.sectorCoordinates.y * this.sectorSize.y + this.sectorSize.y,
            zMin: this.sectorCoordinates.z * this.sectorSize.z,
            zMax: this.sectorCoordinates.z * this.sectorSize.z + this.sectorSize.z
        }
    }

    public getSize(): SectorSize {
        return this.sectorSize;
    }

    public getStarQuantity(): number {
        return this.starQuantity;
    }

    public getStars(): Array<Star> {
        return this.stars;
    }

    public getCoordinates(): SectorCoordinates {
        return this.sectorCoordinates;
    }

    public getGlobalPosition(): GlobalPosition {
        return {
            x: this.sectorCoordinates.x * this.sectorSize.x,
            y: this.sectorCoordinates.y * this.sectorSize.y,
            z: this.sectorCoordinates.z * this.sectorSize.z
        };
    }

    public getGlobalPosition2(): GlobalPosition {
        const globalPosition: GlobalPosition = {
            x: this.sectorCoordinates.x * this.sectorSize.x,
            y: this.sectorCoordinates.y * this.sectorSize.y,
            z: this.sectorCoordinates.z * this.sectorSize.z
        };
        return globalPosition;
    }


}
