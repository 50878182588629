import * as THREE from 'three';
import Galaxy from './Galaxy';
import Camera from './Camera';
import { Star } from './Star';
import CameraControls from './CameraControls';
import { GalaxyConfig } from '../configs/GalaxyConfig';
import { KeyboardControls } from './KeyboardControls';
import { SectorSize } from '../interfaces/GalaxySectorInterfaces';
import { GalaxySize } from '../interfaces/GalaxyInterfaces';
import { drawDebugGalaxySectorBoundaryLines } from '../utils/DebugUtils';

class GalaxyScene {
    private galaxy: Galaxy;
    private scene: THREE.Scene;
    private camera: Camera;
    private cameraControls: CameraControls;
    private keyboardControls: KeyboardControls;
    private renderer: THREE.WebGLRenderer;
    private stars: THREE.Mesh[];

    constructor() {
        this.stars = [];

        document.body.style.cursor = 'none';

        this.galaxy = new Galaxy(
            this.getGalaxySizeFromConfig(),
            this.getSectorSizeFromConfig(),
            GalaxyConfig.StarsPerSector
        );

        this.galaxy.build();

        this.scene = new THREE.Scene();
        this.camera = new Camera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.keyboardControls = new KeyboardControls();
        this.cameraControls = new CameraControls(this.camera);

        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.append(this.renderer.domElement);

        this.galaxy.getGalaxy().forEach((galaxyLayer) => {
            galaxyLayer.forEach((galaxyRow) => {
                galaxyRow.forEach((galaxySector) => {
                    galaxySector.getStars().forEach((star) => {
                        this.addStarToScene(star);
                    })
                    drawDebugGalaxySectorBoundaryLines(this.scene, galaxySector);
                })
            })
        });

        this.animate();
    }

    private getGalaxySizeFromConfig(): GalaxySize {
        return {
            x: GalaxyConfig.GalaxySizeX,
            y: GalaxyConfig.GalaxySizeY,
            z: GalaxyConfig.GalaxySizeZ
        }
    }

    private getSectorSizeFromConfig(): SectorSize {
        return {
            x: GalaxyConfig.SectorSizeX,
            y: GalaxyConfig.SectorSizeY,
            z: GalaxyConfig.SectorSizeZ
        }
    }

    private addStarToScene(star: Star) {
        const starGeometryAndMesh = this.createStarGeometryAndMesh(star);
        starGeometryAndMesh.position.set(
            star.getPosition().x,
            star.getPosition().y,
            star.getPosition().z
        );
        this.scene.add(starGeometryAndMesh);
        this.stars.push(starGeometryAndMesh);
    }

    private createStarGeometryAndMesh(star: Star): THREE.Mesh {
        const geometry = new THREE.SphereGeometry(star.getSize(), star.getSize(), star.getSize());
        const material = new THREE.MeshStandardMaterial({
            color: star.meshMaterial.color,
            roughness: star.meshMaterial.roughness,
            metalness: star.meshMaterial.metalness,
            emissive: star.meshMaterial.emissive,
            emissiveIntensity: star.meshMaterial.emissiveIntensity
        });
        return new THREE.Mesh(geometry, material);
    };

    private animate() {
        requestAnimationFrame(this.animate.bind(this));
        this.renderer.render(this.scene, this.camera);

        const keyPressEvent = this.keyboardControls.getKeyPressEvent();
        this.cameraControls.updateCameraPosition(keyPressEvent);
    }

    public start() {
        this.camera.position.set(GalaxyConfig.CameraStartPositionX, GalaxyConfig.CameraStartPositionY,
            GalaxyConfig.CameraStartPositionZ);
    }
}

export default GalaxyScene;
