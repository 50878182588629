import { PerspectiveCamera, Vector3 } from "three";

export default class Camera extends PerspectiveCamera {
    constructor(fov: number, aspectRatio: number, nearFustrum: number, farFustrum: number) {
        super(fov, aspectRatio, nearFustrum, farFustrum);
    }

    public getPosition(): Vector3 {
        return this.position;
    }

    public setPosition(x: number, y: number, z: number): void {
        this.position.set(x, y, z);
    }

    public getFov(): number {
        return this.fov;
    }

    public setFov(fov: number): void {
        this.fov = fov;
        this.updateProjectionMatrix();
    }

    public getAspectRatio(): number {
        return this.aspect;
    }

    public setAspectRatio(aspectRatio: number): void {
        this.aspect = aspectRatio;
        this.updateProjectionMatrix();
    }

    public getNearFustrum(): number {
        return this.near;
    }

    public setNearFustrum(nearFustrum: number): void {
        this.near = nearFustrum;
        this.updateProjectionMatrix();
    }

    public getFarFustrum(): number {
        return this.far;
    }

    public setFarFustrum(farFustrum: number): void {
        this.far = farFustrum;
        this.updateProjectionMatrix();
    }
}
