export enum StarColorConfig {
    White = "#ffffff",
    Blue = "#55aaff",
    Red = "#ff9999",
}

export enum StarSizeConfig {
    Default = 1,
}

export enum StarMeshMaterialConfig {
    Roughness = 0.5,
    Metalness = 0.5,
    EmissiveIntensity = 0.5
}
