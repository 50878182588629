import './App.css';
import React, { useEffect } from 'react';
import GalaxyScene from './components/GalaxyScene';

const App = () => {
  useEffect(() => {
    const scene = new GalaxyScene();
    scene.start();
  }, []);

  return (
    <div className="galaxy-scene">
      <div className="controls-overlay">
        <pre>
{`
  Maps keyboard keys to camera
  movement based on key position.
  If using QWERTY, maps as follows:

    * Spacebar: Forward
    * Shift: Up
    * Control: Down
    * Q: Rotate Clockwise
    * E: Rotate Counter-clockwise
    * W: Down
    * A: Left
    * S: Up
    * D: Right
`}
        </pre>
      </div>
      <div className="crosshairs" />
    </div>
  );
};

export default App;
