import * as THREE from 'three';
import GalaxySector from '../components/GalaxySector';

export function drawDebugGalaxySectorBoundaryLines(scene: THREE.Scene, sector: GalaxySector) {
    const sectorBoundaryMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });

    const sectorPosition = new THREE.Vector3(sector.getGlobalPosition().x,
        sector.getGlobalPosition().y, sector.getGlobalPosition().z);

    const galaxySectorSize = new THREE.Vector3(sector.getSize().x,
        sector.getSize().y, sector.getSize().z);

    const sectorBoundaryGeometry = new THREE.BufferGeometry().setFromPoints([
        sectorPosition,
        new THREE.Vector3(sectorPosition.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y, sectorPosition.z),
        sectorPosition,
        new THREE.Vector3(sectorPosition.x, sectorPosition.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z),
        new THREE.Vector3(sectorPosition.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y, sectorPosition.z),
        new THREE.Vector3(sectorPosition.x + galaxySectorSize.x, sectorPosition.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x, sectorPosition.y, sectorPosition.z + galaxySectorSize.z),
        new THREE.Vector3(sectorPosition.x, sectorPosition.y + galaxySectorSize.y, sectorPosition.z + galaxySectorSize.z)
    ]);

    // Create the sector's edges line
    const sectorBoundaryLine = new THREE.Line(sectorBoundaryGeometry, sectorBoundaryMaterial);
    scene.add(sectorBoundaryLine);
}
